<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <validation-observer v-slot={invalid}>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--5">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">
                                    <router-link to="/manage/manage-role"><a href=""><i class="fa fa-arrow-left mr-3 text-dark"></i></a></router-link>
                                    {{ tt('update_role') }}
                                </h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="danger" @click="reset">{{ tt('reset') }}</base-button>
                                <base-button size="sm" type="default" @click="update" :disabled="btnSaveOnLoading || invalid">
                                    <span v-if="btnSaveOnLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>{{ tt('update') }}</span>
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mt-5">
                            <div class="row">
                                <div class="col-6">
                                    <label>
                                        <b>{{ tt('role_name') }} <span class="text-danger">*</span></b>
                                    </label>
                                    <base-input :name="tt('role')" :placeholder="tt('role')" v-model="role.name" rules="required"></base-input>
                                </div>
                            </div>

                            <input type="checkbox" @click="checkAll()"> <label>Check All</label>

                            <div v-for="menu in menu">
                                <h3 class="pt-4">{{ menu.name }}</h3>
                                <span v-for="sub in menu.rel_sub_menu">
                                    <h5 class="ml-4 mt-2">{{ sub.name }}</h5>
                                    <div class="row ml-4">
                                        <div class="col-3" v-for="p in sub.rel_permission">
                                            <input type="checkbox" :value="p.name" :id="p.name" v-model="role.permission"> <label :for="p.name">{{ p.type }}</label>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col text-right">
                                <base-button size="sm" type="danger" @click="reset">{{ tt('reset') }}</base-button>
                                <base-button size="sm" type="default" @click="update" :disabled="btnSaveOnLoading || invalid">
                                    <span v-if="btnSaveOnLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>{{ tt('update') }}</span><span v-else>{{ tt('update') }}</span>
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </validation-observer>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import manageMenu from '@/services/setting/manageMenu.service';
    import manageRole from '@/services/manage/manageRole.service';
    import managePermission from '@/services/manage/managePermission.service';

    export default {        
        data() {
            return {
                menu: [],
                role: {
                    name: '',
                    permission: []
                },
                btnSaveOnLoading: false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.getMenu();
            this.getRole(this.$route.params.id);
        },
        methods: {
            getMenu() {
                let context = this;               
                Api(context, manageMenu.get(null, {per_page: 'none'})).onSuccess(function(response) {
                    context.menu = response.data.data.data.data
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.menu = [];
                    }
                })
                .call()
            },
            getRole(id) {
                let context = this;               
                Api(context, manageRole.show(id)).onSuccess(function(response) {
                    context.role.name = response.data.data.name

                    response.data.data.permissions.forEach(function(item){
                        context.role.permission.push(item.name)
                    })
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.role.name = ''
                        context.role.permission = []
                    }
                })
                .call()
            },
            checkAll(){
                let context = this;               

                if (event.target.checked) {
                    Api(context, managePermission.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                        var dataPermission = response.data.data.data.data;
                        dataPermission.forEach(function(item, index) {
                            context.role.permission.push(item.p_name)
                        })
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.role.permission = []
                        }
                    })
                    .call()
                }else{
                    context.role.permission = []
                }
            },
            update() {
                let context = this;    
                context.btnSaveOnLoading = true;

                Api(context, manageRole.update(this.$route.params.id, context.role))
                .onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                    context.$notify({
                        message: 'The permission field is required.',                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
                    context.btnSaveOnLoading = false;   
                })
                .call();
            },
            reset() {
                this.role.permission = []
            }
        }
    };
</script>
